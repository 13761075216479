import React, { useRef, useEffect } from "react";
import slide1 from "../images/img-1.JPG";
import slide2 from "../images/img-2.JPG";
import slide3 from "../images/img-3.JPG";
import slide4 from "../images/img-4.JPG";
import Loader from "../components/Loader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomeScreen = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const handleScroll = (e) => {
      const slider = sliderRef.current;
      if (slider) {
        const scrollDirection = e.deltaY > 0 ? "down" : "up";
        if (scrollDirection === "down") {
          slider.slickNext();
        } else {
          slider.slickPrev();
        }
      }
    };

    window.addEventListener("wheel", handleScroll);
    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
  };

  return (
    <div className="landing-page">
      <div className="slider-wrapper">
        <Slider ref={sliderRef} {...settings}>
          <div className="slide">
            <img src={slide1} className="slide-image" alt="Slide 1" />
          </div>
          <div className="slide">
            <img src={slide2} className="slide-image" alt="Slide 2" />
          </div>
          <div className="slide">
            <img src={slide3} className="slide-image" alt="Slide 3" />
          </div>
          <div className="slide">
            <img src={slide4} className="slide-image" alt="Slide 4" />
          </div>
          <div className="slide">
          <div className="footer text-center">
        <div className="py-5">JOIN OUR NEWSLETTER</div>

        <div className="d-flex place-content-center py-5">
          <ul class="footer-social">
            <li>
              <a href="https://www.tiktok.com/@zara" target="_blank">
                TIKTOK
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/zara/" target="_blank">
                INSTAGRAM
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/Zara" target="_blank">
                FACEBOOK
              </a>
            </li>
            <li>
              <a href="https://twitter.com/ZARA" target="_blank">
                X
              </a>
            </li>
            <li>
              <a href="https://es.pinterest.com/zaraofficial" target="_blank">
                PINTEREST
              </a>
            </li>
            <li>
              <a href="http://www.youtube.com/user/zara" target="_blank">
                YOUTUBE
              </a>
            </li>
            <li>
              <a
                href="https://open.spotify.com/user/r6ivwuv0ebk346hhxo446pbfv"
                target="_blank"
              >
                SPOTIFY
              </a>
            </li>
          </ul>
        </div>

        <div className="d-flex place-content-center py-5">
          <ul class="footer-social">
            <li>
              <a href="#" class="footer-link">
                COOKIES SETTINGS
              </a>
            </li>
            <li>
              <a
                href="https://static.zara.net/static/pdfs/WW/privacy-policy/privacy-policy-en_WW-20240117.pdf"
                target="_blank"
                class="footer-link"
              >
                PRIVACY AND COOKIES POLICY
              </a>
            </li>
            <li>
              <a
                href="https://static.zara.net/static/pdfs/WW/terms-and-conditions/terms-and-conditions-en_WW-20211118.pdf"
                target="_blank"
                class="footer-link"
              >
                TERMS OF USE
              </a>
            </li>
          </ul>
        </div>
      </div>
  </div>
        </Slider>
      </div>
    </div>
  );
};

export default HomeScreen;
