import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  if (window.location.pathname === "/" && !window.location.origin.includes("localhost") && !window.location.origin.includes("127.0.0.1")) {
    return null;
  }

  return (
    <footer>
      <Container>
        <Row>
          {/* <Col className="text-center py-3">All rights reserved</Col> */}
          {/* <div className="footer text-center">
        <div className="py-5">JOIN OUR NEWSLETTER</div>

        <div className="d-flex place-content-center py-5">
          <ul class="footer-social">
            <li>
              <a href="https://www.tiktok.com/@zara" target="_blank">
                TIKTOK
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/zara/" target="_blank">
                INSTAGRAM
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/Zara" target="_blank">
                FACEBOOK
              </a>
            </li>
            <li>
              <a href="https://twitter.com/ZARA" target="_blank">
                X
              </a>
            </li>
            <li>
              <a href="https://es.pinterest.com/zaraofficial" target="_blank">
                PINTEREST
              </a>
            </li>
            <li>
              <a href="http://www.youtube.com/user/zara" target="_blank">
                YOUTUBE
              </a>
            </li>
            <li>
              <a
                href="https://open.spotify.com/user/r6ivwuv0ebk346hhxo446pbfv"
                target="_blank"
              >
                SPOTIFY
              </a>
            </li>
          </ul>
        </div>

        <div className="d-flex place-content-center py-5">
          <ul class="footer-social">
            <li>
              <a href="#" class="footer-link">
                COOKIES SETTINGS
              </a>
            </li>
            <li>
              <a
                href="https://static.zara.net/static/pdfs/WW/privacy-policy/privacy-policy-en_WW-20240117.pdf"
                target="_blank"
                class="footer-link"
              >
                PRIVACY AND COOKIES POLICY
              </a>
            </li>
            <li>
              <a
                href="https://static.zara.net/static/pdfs/WW/terms-and-conditions/terms-and-conditions-en_WW-20211118.pdf"
                target="_blank"
                class="footer-link"
              >
                TERMS OF USE
              </a>
            </li>
          </ul>
        </div>
      </div> */}
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
