import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Image,
  ListGroup,
  ListGroupItem,
  Card,
  Button,
  FormControl,
  Container,
} from "react-bootstrap";
import Rating from "../components/Rating";
import Loader from "../components/Loader";
import Message from "../components/Message";
// import { listProductDetails } from "../actions/productActions";

const ContactScreen = () => {
  const { id } = useParams();
  let history = useNavigate();

  const [qty, setQty] = useState(1);
  const dispatch = useDispatch();
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const addToCartHandler = () => {
    history(`/cart/${id}?qty=${qty}`);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Container className="position-absolute top-50 start-50 translate-middle">
          <Row className="d-flex justify-content-center mb-5">
            <Col md={3}>Whatsapp: +852 96583010</Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col md={3}>Email: camhkshop@gmail.com"</Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ContactScreen;
