import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import Rating from "../components/Rating";

const Product = ({ product }) => {
  let history = useNavigate();

  const addToCartHandler = (id) => {
    history(`/cart/${id}?qty=${1}`);
  };

  return (
    <>
      <Card className="my-3 p-3 rounded">
        <Link to={`/product/${product._id}`}>
          <Card.Img src={product.image} variant="top" />
        </Link>
        <Card.Body>
          <Link to={`/product/${product._id}`}>
            <Card.Title as="div">
              <strong>{product.name}</strong>
            </Card.Title>
          </Link>
          <Card.Text as="div">
            <Rating
              value={product.rating}
              text={`${product.numReviews} reviews`}
            />
          </Card.Text>
          <Card.Text as="h3">${product.price}</Card.Text>

          <Button
            onClick={() => addToCartHandler(product._id)}
            className="btn-block"
            type="button"
            disabled={product?.countInStock === 0}
          >
            Add to Cart
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default Product;
