import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import { saveShippingAddress } from "../actions/cartActions";
import CheckoutSteps from "../components/CheckoutSteps";

const ShippingScreen = () => {
  const history = useNavigate();

  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  const [address, setAddress] = useState(shippingAddress?.address ?? "");
  const [city, setCity] = useState(shippingAddress?.city ?? "");
  const [postalCode, setPostalCode] = useState(shippingAddress?.postalCode??"");
  const [country, setCountry] = useState(shippingAddress?.country??"");

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveShippingAddress({ address, city, postalCode, country }));
    history("/payment");
  };

  return (
    <>
     <Container className="">
      

     
      <FormContainer>
        <CheckoutSteps step1 step2 />
        <h1>Shipping</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="address">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Address"
              value={address}
              required
              onChange={(e) => setAddress(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="city">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter City"
              value={city}
              required
              onChange={(e) => setCity(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="postalCode">
            <Form.Label>Postal Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter postal code"
              value={postalCode}
              required
              onChange={(e) => setPostalCode(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="country">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter country"
              value={country}
              required
              onChange={(e) => setCountry(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button type="submit" variant="primary">
            Continue
          </Button>
        </Form>
      </FormContainer>

      <Row className="justify-content-center mt-4">
          <Col xs={12} md={12}>
            <h2>SF Delivery Information</h2>
          </Col>
        </Row>

        {/* English Version */}
        <Row className="justify-content-md-center mb-5">
          <Col xs={12} md={9}>
            <p>
              All the products will be delivered by SF delivery, please write
              down the code of SF delivery location during the payment process.
            </p>
            <p>
              所有貨品將藉順豐速運寄出，於付款過程時請寫下順豐自助櫃地址的網點代碼。
            </p>
          </Col>
          <Col xs={12} md={3}>
            <Button
              // variant="link"
              href="https://htm.sf-express.com/hk/en/dynamic_function/S.F.Network/SF_Locker/"
              target="_blank"
            >
              Go to SF Locker Locations
            </Button>
            <Button
            className="mt-2"
              // variant="link"
              href="https://htm.sf-express.com/hk/tc/dynamic_function/S.F.Network/SF_Locker/"
              target="_blank"
            >
              前往順豐自助櫃位置
            </Button>
          </Col>
        </Row>
      </Container>
      {/* Footer */}
      <footer>
        <p>Footer Content Here</p>
      </footer>
    </>
  );
};

export default ShippingScreen;
