import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { listProductDetails, updateProduct } from "../actions/productActions";
import { PRODUCT_UPDATE_RESET } from "../constants/productContants";
import axios from "axios";

const ProductEditScreen = () => {
  const { id } = useParams();
  const history = useNavigate();
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [image, setImage] = useState("");
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [countInStocks, setCountInStocks] = useState(0);
  const [description, setDescription] = useState("");
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  useEffect(() => {
    console.log(successUpdate);
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      history(`/admin/productlist`);
    } else {
      if (!product?.name || product?._id !== id) {
        dispatch(listProductDetails(id));
      } else {
        setName(product?.name);
        setPrice(product?.price);
        setImage(product?.image);
        setBrand(product?.brand);
        setCategory(product?.category);
        setCountInStocks(product?.countInStock);
        setDescription(product?.description);
      }
    }
  }, [dispatch, id, product, history, successUpdate]);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(process.env.REACT_APP_URL+`/api/upload`, formData, config);
      setImage(data);
      console.log(data)
      setUploading(false);
    } catch (e) {
      console.log(error);
      setUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    //UPDATE PRODUCT
    dispatch(
      updateProduct({
        _id: id,
        name,
        price,
        image,
        brand,
        category,
        description,
        countInStocks,
      })
    );
  };

  return (
    <>
      {/* <Link to="/admin/productlist" className="btn btn-light my-3">
        Go Back
      </Link> */}
      <FormContainer>
        <h1>Edit Product</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Email"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="image">
              <Form.Label>Image</Form.Label>
              <img
                src={`${process.env.REACT_APP_URL}${image}`}
                alt="product image"
                class="mx-auto img-fluid d-block"
                style={{
                  maxHeight: "200px",
                  objectFit: "contain",
                  width: "200px",
                  height: "200px",
                }}
              />
              <input type="file" name="image" onChange={uploadFileHandler} class="form-control" id="image" />
              {/* <Form.Control
                type="text"
                lable="Enter image url"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control> */}
              {/* <Form.File
                id="image-file"
                label="choose file"
                custom
                onChange={uploadFileHandler}
              ></Form.File> */}
              {uploading && <Loader />}
            </Form.Group>

            <Form.Group controlId="brand">
              <Form.Label>Brand</Form.Label>
              <Form.Control
                type="text"
                lable="Enter brand"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="countInStocks">
              <Form.Label>Count In Stocks</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter count in stocks"
                value={countInStocks}
                onChange={(e) => setCountInStocks(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="category">
              <Form.Label>Category</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button type="Submit" variant="primary" className="mt-3">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default ProductEditScreen;
